import React from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Switch, Dimensions, Alert, Modal, TouchableOpacity as TouchButton, TouchableOpacity, TextInput, Platform, FlatList } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import { Feather, AntDesign } from '@expo/vector-icons';
import RNPickerSelect from 'react-native-picker-select';
import PhoneInput from "react-native-phone-number-input";
import * as Crypto from 'expo-crypto';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Moment from 'moment';
import 'moment/locale/it';
import DateTimePicker from '@react-native-community/datetimepicker';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { MaskedTextInput } from "react-native-mask-text";

export default class AutomazioniScreen extends React.Component {
    constructor(props) {
        super(props)
        this.searchInput = React.createRef()
        this.state = {
            loading: true,
            loadingData: false,
            listAutomazioni: [],
            storyAutomazioni: [],
            idAutomazione: this.props.route.params.idAutomazione,
            nomeAutomazione: this.props.route.params.nomeAutomazione,
            descrizioneAutomazione: this.props.route.params.descrizioneAutomazione,
            urlAutomazione: this.props.route.params.urlAutomazione,
            haveDate: this.props.route.params.haveDate,
            showModal: false,
            listPazienti: [],
            loadingActive: false,
            idPaziente: '',
            cerca: '',
            searchResult: [],
            dateSelect: '',
            timeSelect: '',
            showDateSelect: false,
            showTimeSelect: false,
            showModalEdit: false,
            isEdit: false,
            newDateSelect: '',
            newTimeSelect: '',
            loadingEdit: false,
            idUserAutomation: null,
            dateSelectIos: new Date(),
            pazienteSelect: null,
            cercaAutomazione: '',
            loadingSearch: false,
            page: 1,
            isLoading: false,
            loadingFinish: false,
            isFiltri: false,
            fullAutomazioni: [],
            secondAutomazione: null,
            newUrlAutomation: null,
            widthTitle: 0,
            cercaData: false
        }
    }

    componentDidMount() {
        this.checkAuth()
        this.getAutomazioni()
        if (this.state.haveDate == "1" || this.state.haveDate == 1) {
            this.getListAutomazioni()
        }
        //this.getListofData()
    }

    checkAuth = async () => {
        const id = await AsyncStorage.getItem('id')
        if (id != null && id != undefined && id != '') {

        } else {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            })
        }
    }

    getDataMessages = async (id_automazione) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
    
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_automazione', id_automazione);
    
        try {
            let response = await axios.post(
                'https://clinicautomation.it/app/get_impostazioni_automazioni.php',
                formData,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            
            // I dati JSON sono direttamente accessibili nella proprietà `data` dell'oggetto response
            let json = response.data;
    
            // Chiamata per ottenere i dati di default
            let formDataDefault = new FormData();
            formDataDefault.append('token', token);
            formDataDefault.append('id_automazione', id_automazione);

            let responseDefault = await axios.post(
                'https://clinicautomation.it/app/get_default_messages.php',
                formDataDefault,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            
            // I dati JSON sono direttamente accessibili nella proprietà `data` dell'oggetto response
            let jsonDefault = responseDefault.data;
    
            // Controlla se list è false
            if (!jsonDefault.list) {
                return {}; // Restituisci un oggetto vuoto se list è false
            }
    
            // Funzione di utilità per popolare i dati
            const populateField = (defaultValue, userValue) => {
                return userValue && userValue !== "" ? userValue : defaultValue;
            };
    
            const result = {
                activeAppuntamento: (json.active_appuntamento == 1) ? true : (json.active_appuntamento == 0) ? false : (jsonDefault.active_appuntamento) == 1 ? true : false,
                messaggioAppuntamento: jsonDefault.active_appuntamento == 1 ? populateField(jsonDefault.message_appuntamento, json.active_appuntamento == 1 ? json.message_appuntamento : "") : "",
                appuntamentoTitle: jsonDefault.active_appuntamento == 1 ? populateField(jsonDefault.appuntamento_title, json.active_appuntamento == 1 ? json.appuntamento_title : "") : "",
    
                activeReminder1: (json.active_reminder_1 == 1) ? true : (json.active_reminder_1 == 0) ? false : (jsonDefault.active_reminder_1) == 1 ? true : false,
                messaggioReminder1: jsonDefault.active_reminder_1 == 1 ? populateField(jsonDefault.message_reminder_1, json.active_reminder_1 == 1 ? json.message_reminder_1 : "") : "",
                reminder1Title: jsonDefault.active_reminder_1 == 1 ? populateField(jsonDefault.reminder_1_title, json.active_reminder_1 == 1 ? json.reminder_1_title : "") : "",
                giornoReminder1: jsonDefault.active_reminder_1 == 1 ? populateField(jsonDefault.giorno_reminder_1, json.active_reminder_1 == 1 ? json.giorno_reminder_1 : "") : "",
    
                activeReminder2: (json.active_reminder_2 == 1) ? true : (json.active_reminder_2 == 0) ? false : (jsonDefault.active_reminder_2) == 1 ? true : false,
                messaggioReminder2: jsonDefault.active_reminder_2 == 1 ? populateField(jsonDefault.message_reminder_2, json.active_reminder_2 == 1 ? json.message_reminder_2 : "") : "",
                reminder2Title: jsonDefault.active_reminder_2 == 1 ? populateField(jsonDefault.reminder_2_title, json.active_reminder_2 == 1 ? json.reminder_2_title : "") : "",
                giornoReminder2: jsonDefault.active_reminder_2 == 1 ? populateField(jsonDefault.giorno_reminder_2, json.active_reminder_2 == 1 ? json.giorno_reminder_2 : "") : "",
    
                activeFollowup1: (json.active_followup_1 == 1) ? true : (json.active_followup_1 == 0) ? false : (jsonDefault.active_followup_1) == 1 ? true : false,
                messaggioFollowup1: jsonDefault.active_followup_1 == 1 ? populateField(jsonDefault.message_followup_1, json.active_followup_1 == 1 ? json.message_followup_1 : "") : "",
                followup1Title: jsonDefault.active_followup_1 == 1 ? populateField(jsonDefault.followup_1_title, json.active_followup_1 == 1 ? json.followup_1_title : "") : "",
                giornoFollowup1: jsonDefault.active_followup_1 == 1 ? populateField(jsonDefault.giorno_followup_1, json.active_followup_1 == 1 ? json.giorno_followup_1 : "") : "",
    
                activeFollowup2: (json.active_followup_2 == 1) ? true : (json.active_followup_2 == 0) ? false : (jsonDefault.active_followup_2) == 1 ? true : false,
                messaggioFollowup2: jsonDefault.active_followup_2 == 1 ? populateField(jsonDefault.message_followup_2, json.active_followup_2 == 1 ? json.message_followup_2 : "") : "",
                followup2Title: jsonDefault.active_followup_2 == 1 ? populateField(jsonDefault.followup_2_title, json.active_followup_2 == 1 ? json.followup_2_title : "") : "",
                giornoFollowup2: jsonDefault.active_followup_2 == 1 ? populateField(jsonDefault.giorno_followup_2, json.active_followup_2 == 1 ? json.giorno_followup_2 : "") : "",
    
                activeFollowup3: (json.active_followup_3 == 1) ? true : (json.active_followup_3 == 0) ? false : (jsonDefault.active_followup_3) == 1 ? true : false,
                messaggioFollowup3: jsonDefault.active_followup_3 == 1 ? populateField(jsonDefault.message_followup_3, json.active_followup_3 == 1 ? json.message_followup_3 : "") : "",
                followup3Title: jsonDefault.active_followup_3 == 1 ? populateField(jsonDefault.followup_3_title, json.active_followup_3 == 1 ? json.followup_3_title : "") : "",
                giornoFollowup3: jsonDefault.active_followup_3 == 1 ? populateField(jsonDefault.giorno_followup_3, json.active_followup_3 == 1 ? json.giorno_followup_3 : "") : "",
    
                activeFollowup4: (json.active_followup_4 == 1) ? true : (json.active_followup_4 == 0) ? false : (jsonDefault.active_followup_4) == 1 ? true : false,
                messaggioFollowup4: jsonDefault.active_followup_4 == 1 ? populateField(jsonDefault.message_followup_4, json.active_followup_4 == 1 ? json.message_followup_4 : "") : "",
                followup4Title: jsonDefault.active_followup_4 == 1 ? populateField(jsonDefault.followup_4_title, json.active_followup_4 == 1 ? json.followup_4_title : "") : "",
                giornoFollowup4: jsonDefault.active_followup_4 == 1 ? populateField(jsonDefault.giorno_followup_4, json.active_followup_4 == 1 ? json.giorno_followup_4 : "") : "",
            };
    
            return result;
        } catch (error) {
            console.error("Errore nella fetch dei dati:", error);
            return {}; // Restituisci un oggetto vuoto in caso di errore
        }
    }

    getUrlMessages = (jsonData, nomeCliente, nomeStudio, dataAppuntamento) => {
        let urlScheme = "";
    
        if (jsonData.activeAppuntamento == true) {
            if (jsonData.messaggioAppuntamento !== null && jsonData.messaggioAppuntamento !== "") {
                const messaggioAppuntamento = jsonData.messaggioAppuntamento
                    .replace('%TITLE%', jsonData.appuntamentoTitle)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-appuntamento=${encodeURIComponent(messaggioAppuntamento)}`;
            }
        }
    
        if (jsonData.activeReminder1 == true) {
            if (jsonData.messaggioReminder1 !== null && jsonData.messaggioReminder1 !== "") {
                const messaggioReminder1 = jsonData.messaggioReminder1
                    .replace('%TITLE%', jsonData.reminder1Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-reminder-1=${encodeURIComponent(messaggioReminder1)}&giorno-reminder-1=${encodeURIComponent(jsonData.giornoReminder1)}`;
            }
        }
    
        if (jsonData.activeReminder2 == true) {
            if (jsonData.messaggioReminder2 !== null && jsonData.messaggioReminder2 !== "") {
                const messaggioReminder2 = jsonData.messaggioReminder2
                    .replace('%TITLE%', jsonData.reminder2Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-reminder-2=${encodeURIComponent(messaggioReminder2)}&giorno-reminder-2=${encodeURIComponent(jsonData.giornoReminder2)}`;
            }
        }
    
        if (jsonData.activeFollowup1 == true) {
            if (jsonData.messaggioFollowup1 !== null && jsonData.messaggioFollowup1 !== "") {
                const messaggioFollowup1 = jsonData.messaggioFollowup1
                    .replace('%TITLE%', jsonData.followup1Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-followup-1=${encodeURIComponent(messaggioFollowup1)}&giorno-followup-1=${encodeURIComponent(jsonData.giornoFollowup1)}`;
            }
        }
    
        if (jsonData.activeFollowup2 == true) {
            if (jsonData.messaggioFollowup2 !== null && jsonData.messaggioFollowup2 !== "") {
                const messaggioFollowup2 = jsonData.messaggioFollowup2
                    .replace('%TITLE%', jsonData.followup2Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-followup-2=${encodeURIComponent(messaggioFollowup2)}&giorno-followup-2=${encodeURIComponent(jsonData.giornoFollowup2)}`;
            }
        }
    
        if (jsonData.activeFollowup3 == true) {
            if (jsonData.messaggioFollowup3 !== null && jsonData.messaggioFollowup3 !== "") {
                const messaggioFollowup3 = jsonData.messaggioFollowup3
                    .replace('%TITLE%', jsonData.followup3Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-followup-3=${encodeURIComponent(messaggioFollowup3)}&giorno-followup-3=${encodeURIComponent(jsonData.giornoFollowup3)}`;
            }
        }
    
        if (jsonData.activeFollowup4 == true) {
            if (jsonData.messaggioFollowup4 !== null && jsonData.messaggioFollowup4 !== "") {
                const messaggioFollowup4 = jsonData.messaggioFollowup4
                    /*.replace('%NOME%', nomeCliente)
                    .replace('%STUDIO%', nomeStudio)
                    .replace('%DATA%', dataAppuntamento)*/
                    .replace('%TITLE%', jsonData.followup4Title)
                    .replace(/(\r\n|\n|\r)/g, '\\n');
                urlScheme = `${urlScheme}&messaggio-followup-4=${encodeURIComponent(messaggioFollowup4)}&giorno-followup-4=${encodeURIComponent(jsonData.giornoFollowup4)}`;
            }
        }
    
        return urlScheme;
    }

    getListofData = async () => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        axios.post('https://clinicautomation.it/app/pazienti_list.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ listPazienti: json, loadingData: false })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    getListAutomazioni = async () => {
        const idStudio = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', idStudio);
        axios.post('https://clinicautomation.it/app/get_list_automazioni.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ fullAutomazioni: json })
            } else {
                this.setState({ fullAutomazioni: [] })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    getAutomazioni = async () => {
        let listAutomazioni = this.state.listAutomazioni;
        const id = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_automazione', this.state.idAutomazione);
        formData.append('have_date', (this.state.haveDate == "1" || this.state.haveDate == 1) ? true : false)
        formData.append('page', this.state.page)
        axios.post('https://clinicautomation.it/app/get_automazioni_utenti_2.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loading: false })
            if (json.length > 0) {
                listAutomazioni = [...listAutomazioni, ...json];
                this.setState({ listAutomazioni: listAutomazioni, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false })
            console.error(error);
        })
    }

    searchAutomazioni = async (search) => {
        this.setState({ cercaAutomazione: search, loadingSearch: true, loading: true, page: 1 })
        const id = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_automazione', this.state.idAutomazione);
        formData.append('search_item', search);
        formData.append('page', 1);
        axios.post('https://clinicautomation.it/app/search_automazioni_utente.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            this.setState({ loadingSearch: false, loading: false, isFiltri: true })
            if (json.length > 0) {
                this.setState({ listAutomazioni: json })
            } else {
                this.setState({ listAutomazioni: [] })
            }
        }).catch((error) => {
            this.setState({ loadingSearch: false, loading: false })
            console.error(error);
        })
    }

    searchAutomazioniContinue = async () => {
        let listAutomazioni = this.state.listAutomazioni;
        const id = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('id_automazione', this.state.idAutomazione);
        formData.append('search_item', this.state.cercaAutomazione);
        formData.append('page', this.state.page);
        axios.post('https://clinicautomation.it/app/search_automazioni_utente.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                listAutomazioni = [...listAutomazioni, ...json];
                this.setState({ listAutomazioni: listAutomazioni, isLoading: false })
            } else {
                this.setState({ isLoading: false, loadingFinish: true })
            }
        }).catch((error) => {
            this.setState({ loading: false, isLoading: false })
            console.error(error);
        })
    }

    addAutomazioneUtente = async () => {
        if (this.state.idPaziente != '') {
            this.setState({ loadingActive: true })
            const paziente = this.state.listPazienti.filter(x => x.id == this.state.idPaziente)
            const id = await AsyncStorage.getItem('id')
            const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
            let formData = new FormData();
            formData.append('token', token);
            formData.append('id_utente', id);
            formData.append('id_automazione', this.state.idAutomazione);
            formData.append('id_paziente', this.state.idPaziente);
            axios.post('https://clinicautomation.it/app/add_automazione_utente.php', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(async (response) => {
                const json = response.data;
                if (json.code == true) {
                    /*const telefono = await AsyncStorage.getItem('telefono')
                    const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
                    const via = await AsyncStorage.getItem('via')
                    const citta = await AsyncStorage.getItem('citta')
                    const nomeStudio = await AsyncStorage.getItem('nome_studio')
                    fetch(`${this.state.urlAutomazione}?name=${this.state.pazienteSelect.nome}&surname=${this.state.pazienteSelect.cognome}&phone=${JSON.parse(this.state.pazienteSelect.country_code).callingCode[0]}${this.state.pazienteSelect.telefono}&email=${this.state.pazienteSelect.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${id}&nomeautomazione=${this.state.nomeAutomazione}`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json'
                        }
                    })
                    .then((response) => response.text())
                    .then(async (element) => {
                        this.setState({ loadingActive: false, showModal: false, idPaziente: '', cerca: '', pazienteSelect: null })
                        this.resetListPage()
                        alert(`Automazione attivata! ${json.message}`)
                    }).catch((error) => {
                        this.setState({ loadingActive: false })
                        console.error(error);
                    })*/

                    //Nuovo codice
                    const idAutomazioneUtente = json.id_automazione;
                    const resultMessages = await this.getDataMessages(this.state.idAutomazione)
                    if (Object.keys(resultMessages).length === 0) {
                        this.setState({ loadingActive: false, showModal: false, idPaziente: '', cerca: '', pazienteSelect: null })
                        this.resetListPage()
                        alert(`Automazione attivata! ${json.message}`)
                    } else {
                        const telefono = await AsyncStorage.getItem('telefono')
                        const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
                        const via = await AsyncStorage.getItem('via')
                        const citta = await AsyncStorage.getItem('citta')
                        const nomeStudio = await AsyncStorage.getItem('nome_studio')
                        const dateToSend = '';
                        const urlScheme = this.getUrlMessages(resultMessages, `${this.state.pazienteSelect.nome} ${this.state.pazienteSelect.cognome}`, nomeStudio, dateToSend)
                        fetch(`${this.state.urlAutomazione}?name=${this.state.pazienteSelect.nome}&surname=${this.state.pazienteSelect.cognome}&phone=${JSON.parse(this.state.pazienteSelect.country_code).callingCode[0]}${this.state.pazienteSelect.telefono}&email=${this.state.pazienteSelect.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${id}&status=CREATO&idautomazione=${idAutomazioneUtente}&nomeautomazione=${this.state.nomeAutomazione}${urlScheme}`, {
                            method: 'GET',
                            headers: {
                                Accept: 'application/json'
                            }
                        })
                        .then((response) => response.text())
                        .then(async (element) => {
                            this.setState({ loadingActive: false, showModal: false, idPaziente: '', cerca: '', pazienteSelect: null })
                            this.resetListPage()
                            alert(`Automazione attivata! ${json.message}`)
                        }).catch((error) => {
                            this.setState({ loadingActive: false })
                            console.error(error);
                        })
                    }
                } else {
                    this.setState({ loadingActive: false })
                    alert(`Attenzione! ${json.message}`)
                }
            }).catch((error) => {
                this.setState({ loadingActive: false })
                console.error(error);
            })
        } else {
            alert(`Attenzione! Seleziona un paziente per continuare`)
        }
    }

    addAutomazioneDataUtente = async () => {
        if (this.state.idPaziente != '' && this.state.dateSelect !== "") {
            const validDate = this.isValidDateTime(this.state.dateSelect)
            if (validDate) {
                this.addAutomazioneDataUtenteSecond()
            } else {
                alert("Attenzione! La data ed ora inserita non è valida")
            }
        } else {
            alert("Attenzione! Seleziona un paziente e Inserisci la data dell'appuntamento per continuare")
        }
    }

    isValidDateTime = (dateTimeStr) => {
        if (!dateTimeStr) {
          // Se la stringa è vuota, non è valida
          return false;
        }
      
        // Utilizziamo regex per verificare il formato della stringa (dd-mm-yyyy hh:mm)
        const regex = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
        if (!regex.test(dateTimeStr)) {
          // Se la stringa non corrisponde al formato previsto, non è valida
          return false;
        }
      
        // Convertiamo la stringa in un oggetto data utilizzando il formato previsto (dd-mm-yyyy hh:mm)
        const [datePart, timePart] = dateTimeStr.split(' ');
        const [day, month, year] = datePart.split('-');
        const [hours, minutes] = timePart.split(':');
      
        // Creiamo l'oggetto Date con i componenti ottenuti
        const dateObj = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
      
        // Verifichiamo che la data sia valida
        if (isNaN(dateObj.getTime())) {
          // Se la data non è valida, restituiamo false
          return false;
        }
      
        // Se tutte le verifiche sono superate, la data è valida
        return true;
    }

    addAutomazioneDataUtenteSecond = async () => {
        this.setState({ loadingActive: true })
        const paziente = this.state.listPazienti.filter(x => x.id == this.state.idPaziente)
        const id = await AsyncStorage.getItem('id')
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_utente', id);
        formData.append('id_automazione', this.state.idAutomazione);
        formData.append('id_paziente', this.state.idPaziente);
        formData.append('url_automation', this.state.secondAutomazione);
        formData.append('date_book', this.state.dateSelect.trim());
        axios.post('https://clinicautomation.it/app/add_automazione_data_utente.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                const idAutomazioneUtente = json.id_automazione;
                const resultMessages = await this.getDataMessages(this.state.idAutomazione)
                if (Object.keys(resultMessages).length === 0) {
                    this.setState({ loadingActive: false, showModal: false, dateSelect: '', timeSelect: '', idPaziente: '', cerca: '', pazienteSelect: null, secondAutomazione: null })
                    this.resetListPage()
                    alert(`Automazione attivata! ${json.message}`)
                } else {
                    const telefono = await AsyncStorage.getItem('telefono')
                    const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
                    const via = await AsyncStorage.getItem('via')
                    const citta = await AsyncStorage.getItem('citta')
                    const nomeStudio = await AsyncStorage.getItem('nome_studio')
                    const dateToSend = this.state.dateSelect;
                    const urlScheme = this.getUrlMessages(resultMessages, `${this.state.pazienteSelect.nome} ${this.state.pazienteSelect.cognome}`, nomeStudio, dateToSend)
                    fetch(`${this.state.urlAutomazione}?name=${this.state.pazienteSelect.nome}&surname=${this.state.pazienteSelect.cognome}&phone=${JSON.parse(this.state.pazienteSelect.country_code).callingCode[0]}${this.state.pazienteSelect.telefono}&email=${this.state.pazienteSelect.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${id}&data=${this.state.dateSelect}&status=CREATO&idautomazione=${idAutomazioneUtente}&nomeautomazione=${this.state.nomeAutomazione}${urlScheme}`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json'
                        }
                    })
                    .then((response) => response.text())
                    .then(async (element) => {
                        if (this.state.secondAutomazione !== null && this.state.secondAutomazione !== "") {
                            let secondAutomazioneData = {};
                            const indexSecondAutomazione = this.state.fullAutomazioni.findIndex(e => e.url == this.state.secondAutomazione)
                            if (indexSecondAutomazione !== -1) {
                                secondAutomazioneData = {
                                    id: this.state.fullAutomazioni[indexSecondAutomazione].id,
                                    url: this.state.fullAutomazioni[indexSecondAutomazione].url,
                                    nome: this.state.fullAutomazioni[indexSecondAutomazione].nome
                                }
                            }
                            const resultMessagesSecond = await this.getDataMessages(secondAutomazioneData.id)
                            if (Object.keys(resultMessagesSecond).length === 0) {
                                this.setState({ loadingActive: false, showModal: false, dateSelect: '', timeSelect: '', idPaziente: '', cerca: '', pazienteSelect: null, secondAutomazione: null })
                                this.resetListPage()
                                alert(`Automazione attivata! ${json.message}`)
                            } else {
                                const urlSchemeSecond = this.getUrlMessages(resultMessagesSecond, `${this.state.pazienteSelect.nome} ${this.state.pazienteSelect.cognome}`, nomeStudio, dateToSend)
                                fetch(`${this.state.secondAutomazione}?name=${this.state.pazienteSelect.nome}&surname=${this.state.pazienteSelect.cognome}&phone=${JSON.parse(this.state.pazienteSelect.country_code).callingCode[0]}${this.state.pazienteSelect.telefono}&email=${this.state.pazienteSelect.email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${id}&data=${this.state.dateSelect}&status=CREATO&idautomazione=${idAutomazioneUtente}${urlSchemeSecond}`, {
                                    method: 'GET',
                                    headers: {
                                        Accept: 'application/json'
                                    }
                                })
                                .then((response) => response.text())
                                .then(async (element) => {
                                    this.setState({ loadingActive: false, showModal: false, dateSelect: '', timeSelect: '', idPaziente: '', cerca: '', pazienteSelect: null, secondAutomazione: null })
                                    this.resetListPage()
                                    alert(`Automazione attivata! ${json.message}`)
                                }).catch((error) => {
                                    this.setState({ loadingActive: false })
                                    console.error(error);
                                })
                            }
                        } else {
                            this.setState({ loadingActive: false, showModal: false, dateSelect: '', timeSelect: '', idPaziente: '', cerca: '', pazienteSelect: null, secondAutomazione: null })
                            this.resetListPage()
                            alert(`Automazione attivata! ${json.message}`)
                        }
                    }).catch((error) => {
                        this.setState({ loadingActive: false })
                        console.error(error);
                    })
                }
            } else {
                this.setState({ loadingActive: false })
                alert(`Attenzione! ${json.message}`)
            }
        }).catch((error) => {
            this.setState({ loadingActive: false })
            console.error(error);
        })
    }

    disattivaAutomazioneRequest = (id_automazione, id, nome, cognome, telefono, country, email) => {
        if (confirm("Disattiva automazione! Sei sicuro di voler disattivare l'automazione?") == true) {
            this.disattivaAutomazioneScenari(id_automazione, id, nome, cognome, telefono, country, email)
        }
    }

    disattivaAutomazioneDateRequest = (id_automazione, id, date, nome, cognome, telefono, country, email, url_automation) => {
        if (confirm("Disattiva automazione! Sei sicuro di voler disattivare l'automazione?") == true) {
            this.disattivaAutomazioneDate(id_automazione, id, date, nome, cognome, telefono, country, email, url_automation)
        }
    }

    disattivaAutomazioneDate = async (id_automazione, id, date, nome, cognome, telefono, country, email, url_automation) => {
        const resultMessages = await this.getDataMessages(id_automazione)
        if (Object.keys(resultMessages).length === 0) {
            this.disattivaAutomazione(id)
        } else {
            const idStudio = await AsyncStorage.getItem('id')
            const telefonoStudio = await AsyncStorage.getItem('telefono')
            const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
            const via = await AsyncStorage.getItem('via')
            const citta = await AsyncStorage.getItem('citta')
            const nomeStudio = await AsyncStorage.getItem('nome_studio')
            const urlScheme = this.getUrlMessages(resultMessages, `${nome} ${cognome}`, nomeStudio, date)
            fetch(`${this.state.urlAutomazione}?name=${nome}&surname=${cognome}&phone=${country}${telefono}&email=${email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefonoStudio}&id-studio=${idStudio}&data=${date}&status=ELIMINATO&idautomazione=${id}&nomeautomazione=${this.state.nomeAutomazione}${urlScheme}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                }
            })
            .then((response) => response.text())
            .then(async (element) => {
                if (url_automation !== null && url_automation !== "" && url_automation !== "null") {
                    let secondAutomazioneData = {};
                    const indexSecondAutomazione = this.state.fullAutomazioni.findIndex(e => e.url == url_automation)
                    if (indexSecondAutomazione !== -1) {
                        secondAutomazioneData = {
                            id: this.state.fullAutomazioni[indexSecondAutomazione].id,
                            url: this.state.fullAutomazioni[indexSecondAutomazione].url,
                            nome: this.state.fullAutomazioni[indexSecondAutomazione].nome
                        }
                    }
                    const resultMessagesSecond = await this.getDataMessages(secondAutomazioneData.id)
                    if (Object.keys(resultMessagesSecond).length === 0) {
                        this.disattivaAutomazione(id)
                    } else {
                        const urlSchemeSecond = this.getUrlMessages(resultMessagesSecond, `${nome} ${cognome}`, nomeStudio, date)
                        fetch(`${url_automation}?name=${nome}&surname=${cognome}&phone=${country}${telefono}&email=${email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefonoStudio}&id-studio=${idStudio}&data=${date}&status=ELIMINATO&idautomazione=${id}${urlSchemeSecond}`, {
                            method: 'GET',
                            headers: {
                                Accept: 'application/json'
                            }
                        })
                        .then((response) => response.text())
                        .then(async (element) => {
                            this.disattivaAutomazione(id)
                        }).catch((error) => {
                            console.error(error);
                        })
                    }
                } else {
                    this.disattivaAutomazione(id)
                }
            }).catch((error) => {
                console.error(error);
            })
        }
    }

    disattivaAutomazioneScenari = async (id_automazione, id, nome, cognome, telefono, country, email) => {
        const resultMessages = await this.getDataMessages(id_automazione)
        if (Object.keys(resultMessages).length === 0) {
            this.disattivaAutomazione(id)
        } else {
            const idStudio = await AsyncStorage.getItem('id')
            const telefonoStudio = await AsyncStorage.getItem('telefono')
            const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
            const via = await AsyncStorage.getItem('via')
            const citta = await AsyncStorage.getItem('citta')
            const nomeStudio = await AsyncStorage.getItem('nome_studio')
            const urlScheme = this.getUrlMessages(resultMessages, `${nome} ${cognome}`, nomeStudio, '')
            fetch(`${this.state.urlAutomazione}?name=${nome}&surname=${cognome}&phone=${country}${telefono}&email=${email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefonoStudio}&id-studio=${idStudio}&status=ELIMINATO&idautomazione=${id}&nomeautomazione=${this.state.nomeAutomazione}${urlScheme}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                }
            })
            .then((response) => response.text())
            .then(async (element) => {
                this.disattivaAutomazione(id)
            }).catch((error) => {
                console.error(error);
            })
        }
    }

    sendAutomazioneScenari = async (id_automazione, id, nome, cognome, telefono, country, email) => {
        const resultMessages = await this.getDataMessages(id_automazione)
        if (Object.keys(resultMessages).length === 0) {
            this.resendAutomation(id)
        } else {
            const idStudio = await AsyncStorage.getItem('id')
            const telefonoStudio = await AsyncStorage.getItem('telefono')
            const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
            const via = await AsyncStorage.getItem('via')
            const citta = await AsyncStorage.getItem('citta')
            const nomeStudio = await AsyncStorage.getItem('nome_studio')
            const urlScheme = this.getUrlMessages(resultMessages, `${nome} ${cognome}`, nomeStudio, '')
            fetch(`${this.state.urlAutomazione}?name=${nome}&surname=${cognome}&phone=${country}${telefono}&email=${email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefonoStudio}&id-studio=${idStudio}&status=MODIFICATO&idautomazione=${id}&nomeautomazione=${this.state.nomeAutomazione}${urlScheme}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                }
            })
            .then((response) => response.text())
            .then(async (element) => {
                this.resendAutomation(id)
            }).catch((error) => {
                console.error(error);
            })
        }
    }

    activeAutomazioneScenari = async (id_automazione, id, nome, cognome, telefono, country, email) => {
        const resultMessages = await this.getDataMessages(id_automazione)
        if (Object.keys(resultMessages).length === 0) {
            this.attivaAutomazione(id)
        } else {
            const idStudio = await AsyncStorage.getItem('id')
            const telefonoStudio = await AsyncStorage.getItem('telefono')
            const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
            const via = await AsyncStorage.getItem('via')
            const citta = await AsyncStorage.getItem('citta')
            const nomeStudio = await AsyncStorage.getItem('nome_studio')
            const urlScheme = this.getUrlMessages(resultMessages, `${nome} ${cognome}`, nomeStudio, '')
            fetch(`${this.state.urlAutomazione}?name=${nome}&surname=${cognome}&phone=${country}${telefono}&email=${email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefonoStudio}&id-studio=${idStudio}&status=CREATO&idautomazione=${id}&nomeautomazione=${this.state.nomeAutomazione}${urlScheme}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                }
            })
            .then((response) => response.text())
            .then(async (element) => {
                this.attivaAutomazione(id)
            }).catch((error) => {
                console.error(error);
            })
        }
    }

    disattivaAutomazione = async (id) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', id);
        formData.append('active', 0);
        axios.post('https://clinicautomation.it/app/update_automazione_active.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                this.resetListPage()
                alert(`Automazione disattivata! ${json.message}`)
            } else {
                alert(`Errore! Non è stato possibile disattivare l'automazione. Riprova più tardi`)
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    attivaAutomazione = async (id) => {
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        const idStudio = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', id);
        formData.append('active', 1);
        axios.post('https://clinicautomation.it/app/update_automazione_active.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.code == true) {
                const messaggio = json.message;
                this.resetListPage()
                alert(`Automazione attivata! ${messaggio}`)
            } else {
                alert(`Errore! Non è stato possibile attivare l'automazione. Riprova più tardi`)
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    resendAutomationConfirm = (id_automazione, id, nome, cognome, telefono, country, email) => {
        if (confirm("Attenzione! Sei sicuro di voler inviare nuovamente l'automazione?") == true) {
            this.sendAutomazioneScenari(id_automazione, id, nome, cognome, telefono, country, email)
        }
    }

    resendAutomation = async (id) => {
        alert("Automazione inviata! L'automazione è stata inviata con successo")
        /*const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        const idStudio = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id', id);
        axios.post('https://clinicautomation.it/app/get_utente_automazione.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            alert("Automazione inviata! L'automazione è stata inviata con successo")
        }).catch((error) => {
            console.error(error);
        })*/
    }

    filterResult = (txt) => {
        let text = txt.toUpperCase().trim();
        let space = null;
        if (text !== "") {
            space = this.state.listPazienti.filter(x => x.nome.toUpperCase().match(text));
            if (space.length == 0) {
                space = this.state.listPazienti.filter(x => x.cognome.toUpperCase().match(text));
                if (space.length == 0) {
                    space = this.state.listPazienti.filter(x => x.telefono.match(txt.trim()));
                    this.setState({ searchResult: space });
                } else {
                    this.setState({ searchResult: space });
                }
            } else {
                this.setState({ searchResult: space });
            }
        } else {
            this.setState({ searchResult: [] });
        }
    }

    continuePatientsQueryLike = async (searchText) => {
        this.setState({ cerca: searchText, loadingData: true })
        const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
        let id = await AsyncStorage.getItem('id')
        let formData = new FormData();
        formData.append('token', token);
        formData.append('id_user', id);
        formData.append('page', 1);
        formData.append('search_item', searchText);
        axios.post('https://clinicautomation.it/app/search_pazienti.php', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(async (response) => {
            const json = response.data;
            if (json.length > 0) {
                this.setState({ searchResult: json, loadingData: false })
            } else {
                this.setState({ searchResult: [], loadingData: false })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    selectPaziente = (uid, nome, cognome, item) => {
        this.setState({ idPaziente: uid, searchResult: [], cerca: `${nome} ${cognome}`, pazienteSelect: item })
        this.searchInput.current.blur()
    }

    editActiveDateAutomation = async () => {
        const validateDate = this.isValidDateTime(this.state.newDateSelect)
        if (validateDate) {
            this.setState({ loadingEdit: true })
            if (this.state.isEdit) {
                const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
                const idStudio = await AsyncStorage.getItem('id')
                let formData = new FormData();
                formData.append('token', token);
                formData.append('id', this.state.idUserAutomation);
                formData.append('date_book', this.state.newDateSelect);
                axios.post('https://clinicautomation.it/app/update_automazione_date.php', formData, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(async (response) => {
                    const json = response.data;
                    if (json.code == true) {
                        const messaggio = json.message;
                        const resultMessages = await this.getDataMessages(this.state.idAutomazione)
                        if (Object.keys(resultMessages).length === 0) {
                            this.resetListPage()
                            this.setState({ loadingEdit: false, showModalEdit: false, idUserAutomation: null, isEdit: false, newDateSelect: '', newTimeSelect: '', newUrlAutomation: null })
                            alert(`Data automazione modificata! ${messaggio}`)
                        } else {
                            const elementIndex = this.state.listAutomazioni.findIndex(e => e.id == this.state.idUserAutomation)
                            const telefono = await AsyncStorage.getItem('telefono')
                            const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
                            const via = await AsyncStorage.getItem('via')
                            const citta = await AsyncStorage.getItem('citta')
                            const nomeStudio = await AsyncStorage.getItem('nome_studio')
                            const country = JSON.parse(this.state.listAutomazioni[elementIndex].country_code)
                            const dateToSend = this.state.newDateSelect;
                            const urlScheme = this.getUrlMessages(resultMessages, `${this.state.listAutomazioni[elementIndex].nome} ${this.state.listAutomazioni[elementIndex].cognome}`, nomeStudio, dateToSend)
                            fetch(`${this.state.urlAutomazione}?name=${this.state.listAutomazioni[elementIndex].nome}&surname=${this.state.listAutomazioni[elementIndex].cognome}&phone=${country.callingCode[0]}${this.state.listAutomazioni[elementIndex].telefono}&email=${this.state.listAutomazioni[elementIndex].email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${idStudio}&data=${this.state.newDateSelect}&status=MODIFICATO&idautomazione=${this.state.idUserAutomation}&nomeautomazione=${this.state.nomeAutomazione}${urlScheme}`, {
                                method: 'GET',
                                headers: {
                                    Accept: 'application/json'
                                }
                            })
                            .then((response) => response.text())
                            .then(async (element) => {
                                if (this.state.newUrlAutomation !== null && this.state.newUrlAutomation !== "null" && this.state.newUrlAutomation !== "") {
                                    let secondAutomazioneData = {};
                                    const indexSecondAutomazione = this.state.fullAutomazioni.findIndex(e => e.url == this.state.newUrlAutomation)
                                    if (indexSecondAutomazione !== -1) {
                                        secondAutomazioneData = {
                                            id: this.state.fullAutomazioni[indexSecondAutomazione].id,
                                            url: this.state.fullAutomazioni[indexSecondAutomazione].url,
                                            nome: this.state.fullAutomazioni[indexSecondAutomazione].nome
                                        }
                                    }
                                    const resultMessagesSecond = await this.getDataMessages(secondAutomazioneData.id)
                                    if (Object.keys(resultMessagesSecond).length === 0) {
                                        this.resetListPage()
                                        this.setState({ loadingEdit: false, showModalEdit: false, idUserAutomation: null, isEdit: false, newDateSelect: '', newTimeSelect: '', newUrlAutomation: null })
                                        alert(`Data automazione modificata! ${messaggio}`)
                                    } else {
                                        const urlSchemeSecond = this.getUrlMessages(resultMessagesSecond, `${this.state.listAutomazioni[elementIndex].nome} ${this.state.listAutomazioni[elementIndex].cognome}`, nomeStudio, dateToSend)
                                        fetch(`${this.state.newUrlAutomation}?name=${this.state.listAutomazioni[elementIndex].nome}&surname=${this.state.listAutomazioni[elementIndex].cognome}&phone=${country.callingCode[0]}${this.state.listAutomazioni[elementIndex].telefono}&email=${this.state.listAutomazioni[elementIndex].email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${idStudio}&data=${this.state.newDateSelect}&status=MODIFICATO&idautomazione=${this.state.idUserAutomation}${urlSchemeSecond}`, {
                                            method: 'GET',
                                            headers: {
                                                Accept: 'application/json'
                                            }
                                        })
                                        .then((response) => response.text())
                                        .then(async (element) => {
                                            this.resetListPage()
                                            this.setState({ loadingEdit: false, showModalEdit: false, idUserAutomation: null, isEdit: false, newDateSelect: '', newTimeSelect: '', newUrlAutomation: null })
                                            alert(`Data automazione modificata! ${messaggio}`)
                                        }).catch((error) => {
                                            console.error(error);
                                        })
                                    }
                                } else {
                                    this.resetListPage()
                                    this.setState({ loadingEdit: false, showModalEdit: false, idUserAutomation: null, isEdit: false, newDateSelect: '', newTimeSelect: '', newUrlAutomation: null })
                                    alert(`Data automazione modificata! ${messaggio}`)
                                }
                            }).catch((error) => {
                                console.error(error);
                            })
                        }
                    } else {
                        this.setState({ loadingEdit: false })
                        alert(`Errore! Non è stato possibile attivare l'automazione. Riprova più tardi`)
                    }
                }).catch((error) => {
                    console.error(error);
                })
            } else {
                const token = CryptoJS.MD5('8Hsh-zjh83sdhy83Hbz').toString(CryptoJS.enc.Hex)
                const idStudio = await AsyncStorage.getItem('id')
                let formData = new FormData();
                formData.append('token', token);
                formData.append('id', this.state.idUserAutomation);
                formData.append('date_book', this.state.newDateSelect);
                axios.post('https://clinicautomation.it/app/update_automazione_date.php', formData, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(async (response) => {
                    const json = response.data;
                    if (json.code == true) {
                        let formDataNew = new FormData();
                        formDataNew.append('token', token);
                        formDataNew.append('id', this.state.idUserAutomation);
                        formDataNew.append('active', 1);
                        axios.post('https://clinicautomation.it/app/update_automazione_active.php', formDataNew, {
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(async (response) => {
                            const json = response.data;
                            if (json.code == true) {
                                const messaggio = json.message;
                                const resultMessages = await this.getDataMessages(this.state.idAutomazione)
                                if (Object.keys(resultMessages).length === 0) {
                                    this.resetListPage()
                                    this.setState({ loadingEdit: false, showModalEdit: false, idUserAutomation: null, isEdit: false, newDateSelect: '', newTimeSelect: '', newUrlAutomation: null })
                                    alert(`Automazione attivata! ${messaggio}`)
                                } else {
                                    const elementIndex = this.state.listAutomazioni.findIndex(e => e.id == this.state.idUserAutomation)
                                    const telefono = await AsyncStorage.getItem('telefono')
                                    const country_code = JSON.parse(await AsyncStorage.getItem('country_code'))
                                    const via = await AsyncStorage.getItem('via')
                                    const citta = await AsyncStorage.getItem('citta')
                                    const nomeStudio = await AsyncStorage.getItem('nome_studio')
                                    const country = JSON.parse(this.state.listAutomazioni[elementIndex].country_code)
                                    const dateToSend = this.state.newDateSelect;
                                    const urlScheme = this.getUrlMessages(resultMessages, `${this.state.listAutomazioni[elementIndex].nome} ${this.state.listAutomazioni[elementIndex].cognome}`, nomeStudio, dateToSend)
                                    fetch(`${this.state.urlAutomazione}?name=${this.state.listAutomazioni[elementIndex].nome}&surname=${this.state.listAutomazioni[elementIndex].cognome}&phone=${country.callingCode[0]}${this.state.listAutomazioni[elementIndex].telefono}&email=${this.state.listAutomazioni[elementIndex].email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${idStudio}&data=${this.state.newDateSelect}&status=CREATO&idautomazione=${this.state.idUserAutomation}&nomeautomazione=${this.state.nomeAutomazione}${urlScheme}`, {
                                        method: 'GET',
                                        headers: {
                                            Accept: 'application/json'
                                        }
                                    })
                                    .then((response) => response.text())
                                    .then(async (element) => {
                                        if (this.state.newUrlAutomation !== null && this.state.newUrlAutomation !== "null" && this.state.newUrlAutomation !== "") {
                                            let secondAutomazioneData = {};
                                            const indexSecondAutomazione = this.state.fullAutomazioni.findIndex(e => e.url == this.state.newUrlAutomation)
                                            if (indexSecondAutomazione !== -1) {
                                                secondAutomazioneData = {
                                                    id: this.state.fullAutomazioni[indexSecondAutomazione].id,
                                                    url: this.state.fullAutomazioni[indexSecondAutomazione].url,
                                                    nome: this.state.fullAutomazioni[indexSecondAutomazione].nome
                                                }
                                            }
                                            const resultMessagesSecond = await this.getDataMessages(secondAutomazioneData.id)
                                            if (Object.keys(resultMessagesSecond).length === 0) {
                                                this.resetListPage()
                                                this.setState({ loadingEdit: false, showModalEdit: false, idUserAutomation: null, isEdit: false, newDateSelect: '', newTimeSelect: '', newUrlAutomation: null })
                                                alert(`Automazione attivata! ${messaggio}`)
                                            } else {
                                                const urlSchemeSecond = this.getUrlMessages(resultMessagesSecond, `${this.state.listAutomazioni[elementIndex].nome} ${this.state.listAutomazioni[elementIndex].cognome}`, nomeStudio, dateToSend)
                                                fetch(`${this.state.newUrlAutomation}?name=${this.state.listAutomazioni[elementIndex].nome}&surname=${this.state.listAutomazioni[elementIndex].cognome}&phone=${country.callingCode[0]}${this.state.listAutomazioni[elementIndex].telefono}&email=${this.state.listAutomazioni[elementIndex].email}&studio=${nomeStudio}&address-studio=${via}, ${citta}&phone-studio=${country_code.callingCode[0]}${telefono}&id-studio=${idStudio}&data=${this.state.newDateSelect}&status=CREATO&idautomazione=${this.state.idUserAutomation}${urlSchemeSecond}`, {
                                                    method: 'GET',
                                                    headers: {
                                                        Accept: 'application/json'
                                                    }
                                                })
                                                .then((response) => response.text())
                                                .then(async (element) => {
                                                    this.resetListPage()
                                                    this.setState({ loadingEdit: false, showModalEdit: false, idUserAutomation: null, isEdit: false, newDateSelect: '', newTimeSelect: '', newUrlAutomation: null })
                                                    alert(`Automazione attivata! ${messaggio}`)
                                                }).catch((error) => {
                                                    console.error(error);
                                                })
                                            }
                                        } else {
                                            this.resetListPage()
                                            this.setState({ loadingEdit: false, showModalEdit: false, idUserAutomation: null, isEdit: false, newDateSelect: '', newTimeSelect: '', newUrlAutomation: null })
                                            alert(`Automazione attivata! ${messaggio}`)
                                        }
                                    }).catch((error) => {
                                        console.error(error);
                                    })
                                }
                            } else {
                                this.setState({ loadingEdit: false })
                                alert(`Errore! Non è stato possibile attivare l'automazione. Riprova più tardi`)
                            }
                        }).catch((error) => {
                            console.error(error);
                        })
                    } else {
                        this.setState({ loadingEdit: false })
                        alert(`Errore! Non è stato possibile attivare l'automazione. Riprova più tardi`)
                    }
                }).catch((error) => {
                    console.error(error);
                })
            }
        } else {
            alert("Attenzione! La data ed ora inserita non è valida")
        }
    }

    changeDate = (event, date) => {
        if (Platform.OS == 'android') {
            if (event.type == 'dismissed') {
              this.setState({ showDateSelect: false })
            } else {
              this.setState({ showDateSelect: false, dateSelect: Moment(date).format('DD-MM-YYYY') })
            }
        } else {
            this.setState({ dateSelect: Moment(date).format('DD-MM-YYYY HH:mm'), dateSelectIos: date })
        }
    }

    changeDateEdit = (event, date) => {
        if (Platform.OS == 'android') {
            if (event.type == 'dismissed') {
              this.setState({ showDateSelect: false })
            } else {
              this.setState({ showDateSelect: false, newDateSelect: Moment(date).format('DD-MM-YYYY') })
            }
        } else {
            this.setState({ newDateSelect: Moment(date).format('DD-MM-YYYY HH:mm'), dateSelectIos: date })
        }
    }

    changeTime = (event, date) => {
        if (Platform.OS == 'android') {
            if (event.type == 'dismissed') {
              this.setState({ showTimeSelect: false })
            } else {
              this.setState({ showTimeSelect: false, timeSelect: Moment(date).format('HH:mm') })
            }
        }
    }

    changeTimeEdit = (event, date) => {
        if (Platform.OS == 'android') {
            if (event.type == 'dismissed') {
              this.setState({ showTimeSelect: false })
            } else {
              this.setState({ showTimeSelect: false, newTimeSelect: Moment(date).format('HH:mm') })
            }
        }
    }

    renderItem = ({item, index}) => {
        const isEven = index % 2 === 0;
        let country_code = {};
        let userData = [];
        if (item.id_paziente == null) {
            userData = JSON.parse(item.user)
        } else {
            country_code = JSON.parse(item.country_code)
        }
        if (item.date_book !== null && item.date_book !== "") {
            return(
                <View key={index} style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: (item.active == 1) ? 10 : 20, justifyContent: 'space-between', marginBottom: 15, marginRight: (!isEven) ? 0 : '6%' }]}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Feather name='activity' color='#0070FF' size={30} />
                        <View style={{ marginLeft: 15 }}>
                            {(item.id_paziente !== null)
                                ?
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>{item.nome} {item.cognome}</Text>
                                :
                                <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>{userData.nome} {userData.cognome}</Text>
                            }
                            {(item.active == 1)
                                ?
                                <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Automazione attiva</Text>
                                :
                                <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Automazione non attiva</Text>
                            }
                            <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Data appuntamento:{'\n'}{item.date_book}</Text>
                        </View>
                    </View>
                    {(item.id_appuntamento == null || item.id_appuntamento == "")
                        ?
                        (item.active == 1)
                            ?
                            <View>
                                {(item.id_paziente !== null)
                                    ?
                                    <>
                                    <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fc3535', marginBottom: 8 }]} onPress={() => this.disattivaAutomazioneDateRequest(item.id_automazione, item.id, item.date_book, item.nome, item.cognome, item.telefono, country_code.callingCode[0], item.email, item.url_automation)}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Disattiva</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.buttonMore} onPress={() => this.setState({ isEdit: true, newDateSelect: item.date_book, newTimeSelect: '', idUserAutomation: item.id, newUrlAutomation: item.url_automation, showModalEdit: true })}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Modifica</Text>
                                    </TouchableOpacity>
                                    </>
                                    :
                                    <>
                                    <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fc3535', marginBottom: 8 }]} onPress={() => this.disattivaAutomazioneDateRequest(item.id_automazione, item.id, item.date_book, userData.nome, userData.cognome, userData.telefono, userData.country_code.callingCode[0], userData.email, item.url_automation)}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Disattiva</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.buttonMore} onPress={() => this.setState({ isEdit: true, newDateSelect: item.date_book, newTimeSelect: '', idUserAutomation: item.id, newUrlAutomation: item.url_automation, showModalEdit: true })}>
                                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Modifica</Text>
                                    </TouchableOpacity>
                                    </>
                                }
                            </View>
                            :
                            <TouchableOpacity style={styles.buttonMore} onPress={() => this.setState({ isEdit: false, newDateSelect: item.date_book, idUserAutomation: item.id, newUrlAutomation: item.url_automation, showModalEdit: true })}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Attiva</Text>
                            </TouchableOpacity>
                        :
                        null
                    }
                </View>
            )
        } else {
            return(
                <View key={index} style={[styles.buttonLink, styles.responsiveCallButton, { paddingHorizontal: 20, paddingVertical: (item.active == 1) ? 10 : 20, justifyContent: 'space-between', marginBottom: 15, marginRight: (!isEven) ? 0 : '6%' }]}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Feather name='activity' color='#0070FF' size={30} />
                        <View style={{ marginLeft: 15 }}>
                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>{item.nome} {item.cognome}</Text>
                            {(item.active == 1)
                                ?
                                <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Automazione attiva</Text>
                                :
                                <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Automazione non attiva</Text>
                            }
                        </View>
                    </View>
                    {(item.active == 1)
                        ?
                        (item.id_paziente !== null)
                            ?
                            <View>
                                <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fc3535', marginBottom: 8 }]} onPress={() => this.disattivaAutomazioneRequest(item.id_automazione, item.id, item.nome, item.cognome, item.telefono, country_code.callingCode[0], item.email)}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Disattiva</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.buttonMore} onPress={() => this.resendAutomationConfirm(item.id_automazione, item.id, item.nome, item.cognome, item.telefono, country_code.callingCode[0], item.email)}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Invia di nuovo</Text>
                                </TouchableOpacity>
                            </View>
                            :
                            <View>
                                <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fc3535', marginBottom: 8 }]} onPress={() => this.disattivaAutomazioneRequest(item.id_automazione, item.id, userData.nome, userData.cognome, userData.telefono, userData.country_code.callingCode[0], userData.email)}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Disattiva</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.buttonMore} onPress={() => this.resendAutomationConfirm(item.id_automazione, item.id, userData.nome, userData.cognome, userData.telefono, userData.country_code.callingCode[0], userData.email)}>
                                    <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Invia di nuovo</Text>
                                </TouchableOpacity>
                            </View>
                        :
                        (item.id_paziente !== null)
                            ?
                            <TouchableOpacity style={styles.buttonMore} onPress={() => this.activeAutomazioneScenari(item.id_automazione, item.id, item.nome, item.cognome, item.telefono, country_code.callingCode[0], item.email)}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Attiva</Text>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity style={styles.buttonMore} onPress={() => this.activeAutomazioneScenari(item.id_automazione, item.id, userData.nome, userData.cognome, userData.telefono, userData.country_code.callingCode[0], userData.email)}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#fff' }}>Attiva</Text>
                            </TouchableOpacity>
                    }
                </View>
            )
        }
    }

    renderFooter = () => {
        return(
            this.state.isLoading && !this.state.loadingFinish ?
            <View style={{ marginTop: 10, alignItems: 'center' }}>
                <ActivityIndicator size='small' color='#fff' />
            </View> : null
        );
    }

    handleMore = () => {
        if (!this.state.isFiltri) {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                this.getAutomazioni()
            }, 1000);
        } else {
            this.setState({ page: this.state.page + 1, isLoading: true })
            setTimeout(() => {
                this.searchAutomazioniContinue()
            }, 1000);
        }
    }

    resetListPage = () => {
        this.setState({ page: 1, isFiltri: false, loading: true, listAutomazioni: [], loadingSearch: false, cercaAutomazione: '' })
        setTimeout(() => {
            this.getAutomazioni()
        }, 1000);
    }

    handleGoBack = () => {
        if (this.props.navigation.canGoBack()) {
            this.props.navigation.goBack();
        } else {
            this.props.navigation.navigate('App'); // Schermata di fallback
        }
    }

    render() {
        return(
            <View style={styles.container}>
                <StatusBar translucent={true} barStyle='light-content' backgroundColor='transparent' />
                <View style={styles.header}>
                    <TouchableOpacity style={styles.backButton} onPress={this.handleGoBack}>
                        <Feather name='arrow-left' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 6 }}>Dettaglio</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.backButton} onPress={() => this.setState({ showModal: true })}>
                        <AntDesign name='pluscircleo' color='#fff' size={23} />
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14, color: '#fff', marginLeft: 7 }}>Aggiungi</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ marginBottom: 25, paddingTop: 20, paddingHorizontal: 20, alignItems: 'center' }}>
                    <View style={[styles.containerTitle, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '40%' }]} onLayout={(event) => {
                        const {x, y, width, height} = event.nativeEvent.layout;
                        this.setState({ widthTitle: width })
                    }}>
                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, color: '#2484FF' }}>Automazione:</Text>
                        <Text style={{ fontFamily: 'DM-Bold', fontSize: 20, color: '#0070FF' }}>{this.state.nomeAutomazione}</Text>
                        {(this.state.descrizioneAutomazione != '' && this.state.descrizioneAutomazione != null)
                            &&
                            <Text style={{ fontFamily: 'DM-Regular', fontSize: 13 }}>{this.state.descrizioneAutomazione}</Text>
                        }
                    </View>
                </View>
                <View style={{ paddingHorizontal: 20, alignItems: 'center' }}>
                    <View style={{ alignItems: 'flex-end', marginBottom: 10 }}>
                        <TouchableOpacity style={[styles.buttonMore, { backgroundColor: '#fff' }]} onPress={() => this.setState({ cercaData: !this.state.cercaData })}>
                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 15, color: '#0070FF' }}>
                                {(this.state.cercaData)
                                    ?
                                    'Cerca per nome'
                                    :
                                    'Cerca per data'
                                }
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                        <View style={styles.containerIcon}>
                            {(this.state.loadingSearch)
                                ?
                                <ActivityIndicator size='small' color='#000' />
                                :
                                <AntDesign name='search1' color='#000' size={23} />
                            }
                        </View>
                        {(this.state.widthTitle > 0)
                            ?
                            (this.state.cercaData)
                                ?
                                <MaskedTextInput
                                    mask="99-99-9999 99:99"
                                    value={this.state.cercaAutomazione}
                                    onChangeText={(cerca) => this.searchAutomazioni(cerca)}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder={`Es. ${Moment(new Date()).format('DD-MM-YYYY HH:mm')}`}
                                    style={[styles.input, { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, paddingLeft: 0, width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 160 : (this.state.widthTitle - 120), marginRight: 10, backgroundColor: '#FFF' }]}
                                    keyboardType='number-pad'
                                />
                                :
                                <TextInput
                                    style={[styles.input, { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, paddingLeft: 0, width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 160 : (this.state.widthTitle - 120), marginRight: 10, backgroundColor: '#FFF' }]}
                                    placeholderTextColor='#D3D6D7'
                                    placeholder="Cerca l'automazione"
                                    value={this.state.cercaAutomazione}
                                    onChangeText={(cerca) => this.searchAutomazioni(cerca)}
                                />
                            :
                            null
                        }
                        <TouchableOpacity disabled={(this.state.cercaAutomazione !== '') ? false : true} style={[styles.backButton, { backgroundColor: (this.state.cercaAutomazione !== '') ? '#FFF' : '#D3D6D7', width: 55, height: 55, paddingHorizontal: 0 }]} onPress={this.resetListPage}>
                            <AntDesign name='closecircleo' color={(this.state.cercaAutomazione !== '') ? '#0070FF' : 'grey'} size={23} />
                        </TouchableOpacity>
                    </View>
                </View>
                {(this.state.loading)
                    ?
                    <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 10, paddingBottom: Dimensions.get('screen').height / 4 }}>
                        <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20, justifyContent: 'center' }]}>
                            <ActivityIndicator size='small' color='#0070FF' />
                        </View>
                    </View>
                    :
                    (this.state.listAutomazioni.length > 0)
                        ?
                        <FlatList
                            data={this.state.listAutomazioni}
                            renderItem={this.renderItem}
                            contentContainerStyle={{ 
                                paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150,
                                paddingTop: 10,
                                paddingBottom: Dimensions.get('screen').height / 4
                            }}
                            numColumns={(Dimensions.get('screen').width <= 500) ? 1 : 2}
                            keyExtractor={(item, index) => index.toString()}
                            showsVerticalScrollIndicator={false}
                            ListFooterComponent={this.renderFooter}
                            onEndReachedThreshold={0.5}
                            onEndReached={this.handleMore}
                        />
                        :
                        <View style={{ paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 20 : 150, paddingTop: 10, paddingBottom: Dimensions.get('screen').height / 4 }}>
                            <View style={[styles.buttonLink, { width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : Dimensions.get('screen').width - 300, paddingHorizontal: 20, paddingVertical: 20 }]}>
                                <Feather name='alert-triangle' color='#fc3535' size={30} />
                                <View style={{ marginLeft: 15 }}>
                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>Non sono presenti automazioni</Text>
                                    <Text style={{ fontFamily: 'DM-Regular', fontSize: 12, color: '#6d6e72' }}>Aggiungi la prima automazione</Text>
                                </View>
                            </View>
                        </View>
                }
                <View style={styles.positionCricle}>
                    <View style={styles.circle}></View>
                </View>
                <Modal
                    visible={this.state.showModal}
                    animationType='slide'
                    presentationStyle='pageSheet'
                    transparent={false}
                    onRequestClose={() => {
                        this.setState({ showModal: false, dateSelectIos: new Date(), showDateSelect: false, showTimeSelect: false })
                    }}
                >
                    <View style={[styles.container, { backgroundColor: '#FFF' }]}>
                        <View style={[styles.header, { paddingTop: 10 }]}>
                            <TouchButton style={styles.backButton} onPress={() => this.setState({ showModal: false, dateSelectIos: new Date(), showDateSelect: false, showTimeSelect: false })}>
                                <Feather name='arrow-left' color='#fff' size={23} />
                            </TouchButton>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={[styles.containerCenter, { marginBottom: Dimensions.get('screen').height / 3 }]}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center', marginBottom: 25 }}>Attiva automazione</Text>
                                <View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginRight: 7 }}>Seleziona il paziente</Text>
                                        {(this.state.loadingData)
                                            &&
                                            <ActivityIndicator size='small' color='#000' />
                                        }
                                    </View>
                                    <TextInput
                                        style={[styles.input, { borderBottomLeftRadius: (this.state.searchResult.length > 0) ? 0 : 10, borderBottomRightRadius: (this.state.searchResult.length > 0) ? 0 : 10 }]}
                                        placeholderTextColor='#bfbfbf'
                                        placeholder='Inserisci il nome del Paziente'c
                                        value={this.state.cerca}
                                        //onChangeText={(cerca) => {this.setState({ cerca }); this.filterResult(cerca)}}
                                        onChangeText={(cerca) => this.continuePatientsQueryLike(cerca)}
                                        ref={this.searchInput}
                                        //editable={(this.state.loadingData) ? false : true}
                                    />
                                    {(this.state.searchResult.length > 0)
                                        &&
                                        this.state.searchResult.map((item, index) => {
                                            let counter = index + 1;
                                            const country_code = JSON.parse(item.country_code)
                                            return(
                                                <TouchButton onPress={() => this.selectPaziente(item.id, item.nome.trim(), item.cognome.trim(), item)} key={item.id} style={[styles.listUser, {
                                                    borderBottomLeftRadius: (counter == this.state.searchResult.length) ? 10 : 0,
                                                    borderBottomRightRadius: (counter == this.state.searchResult.length) ? 10 : 0
                                                }]}>
                                                    <Text style={{ fontFamily: 'DM-Medium', fontSize: 16 }}>
                                                        {item.nome.trim()} {item.cognome.trim()}{'\n'}
                                                        <Text style={{ fontFamily: 'DM-Medium', fontSize: 14 }}>+{country_code.callingCode[0]}{item.telefono}</Text>
                                                    </Text>
                                                </TouchButton>
                                            )
                                        })
                                    }
                                    {/*<RNPickerSelect
                                        placeholder={{ label: 'Seleziona il paziente', value: null }}
                                        doneText='Fine'
                                        style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                        onValueChange={(idPaziente) => this.setState({ idPaziente })}
                                        items={this.state.listPazienti.map((item, index) => {
                                            return { key: index, label: `${item.nome.trim()} ${item.cognome.trim()}`, value: item.id }
                                        })}
                                    />*/}
                                    {(this.state.haveDate == "1" || this.state.haveDate == 1)
                                        ?
                                        <View style={{ marginVertical: 20 }}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Data appuntamento</Text>
                                            <MaskedTextInput
                                                mask="99-99-9999 99:99"
                                                value={this.state.dateSelect}
                                                onChangeText={(dateSelect) => this.setState({ dateSelect })}
                                                placeholderTextColor='#bfbfbf'
                                                placeholder={`Data appuntamento Es. ${Moment(new Date()).format('DD-MM-YYYY HH:mm')}`}
                                                style={styles.input}
                                            />
                                        </View>
                                        :
                                        null
                                    }
                                    {(this.state.haveDate == "1" || this.state.haveDate == 1)
                                        &&
                                        <View style={{ marginBottom: 20 }}>
                                            <Text style={{ fontFamily: 'DM-Medium', fontSize: 16, marginBottom: 10 }}>Seleziona una seconda automazione</Text>
                                            <RNPickerSelect
                                                placeholder={{ label: 'Seleziona automazione', value: null }}
                                                doneText='Fine'
                                                style={{ viewContainer: [styles.input, { marginBottom: 0, justifyContent: 'center' }], inputWeb: { fontFamily: 'DM-Regular', fontSize: 16, color: '#000', borderWidth: 0, backgroundColor: '#f5f5f5' } }}
                                                onValueChange={(secondAutomazione) => this.setState({ secondAutomazione })}
                                                items={this.state.fullAutomazioni.map((item, index) => {
                                                    return { key: index, value: item.url, label: item.nome };
                                                })}
                                            />
                                        </View>
                                    }
                                </View>
                                <View style={{ alignItems: 'center' }}>
                                    {(this.state.haveDate == "0" || this.state.haveDate == 0)
                                        ?
                                        <TouchButton style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={this.addAutomazioneUtente}>
                                            {(this.state.loadingActive)
                                                ?
                                                <ActivityIndicator color='#fff' size='small' />
                                                :
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Attiva</Text>
                                            }
                                        </TouchButton>
                                        :
                                        <TouchButton style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={this.addAutomazioneDataUtente}>
                                            {(this.state.loadingActive)
                                                ?
                                                <ActivityIndicator color='#fff' size='small' />
                                                :
                                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>Attiva</Text>
                                            }
                                        </TouchButton>
                                    }
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </Modal>
                <Modal
                    visible={this.state.showModalEdit}
                    animationType='slide'
                    presentationStyle='pageSheet'
                    transparent={false}
                    onRequestClose={() => {
                        this.setState({ showModalEdit: false, isEdit: false, newDateSelect: '', newTimeSelect: '', idUserAutomation: null,  dateSelectIos: new Date(), showDateSelect: false, showTimeSelect: false })
                    }}
                >
                    <View style={[styles.container, { backgroundColor: '#FFF' }]}>
                        <View style={[styles.header, { paddingTop: 10 }]}>
                            <TouchButton style={styles.backButton} onPress={() => this.setState({ showModalEdit: false, isEdit: false, newDateSelect: '', newTimeSelect: '', idUserAutomation: null, dateSelectIos: new Date(), showDateSelect: false, showTimeSelect: false })}>
                                <Feather name='arrow-left' color='#fff' size={23} />
                            </TouchButton>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={[styles.containerCenter, { marginBottom: 20 }]}>
                                <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, textAlign: 'center', marginBottom: 25 }}>{(this.state.isEdit) ? 'Modifica' : 'Attiva'} automazione</Text>
                                <MaskedTextInput
                                    mask="99-99-9999 99:99"
                                    value={this.state.newDateSelect}
                                    onChangeText={(newDateSelect) => this.setState({ newDateSelect })}
                                    placeholderTextColor='#bfbfbf'
                                    placeholder={`Data appuntamento Es. ${Moment(new Date()).format('DD-MM-YYYY HH:mm')}`}
                                    style={styles.input}
                                />
                                <View style={{ alignItems: 'center' }}>
                                    <TouchButton style={[styles.button, { width: (Dimensions.get('screen').width <= 500) ? '100%' : '30%' }]} onPress={this.editActiveDateAutomation}>
                                        {(this.state.loadingEdit)
                                            ?
                                            <ActivityIndicator color='#fff' size='small' />
                                            :
                                            <Text style={{ fontFamily: 'DM-Bold', fontSize: 18, color: '#fff' }}>
                                                {(this.state.isEdit)
                                                    ?
                                                    'Salva'
                                                    :
                                                    'Attiva'
                                                }
                                            </Text>
                                        }
                                    </TouchButton>
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </Modal>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#2484FF',
        overflow: 'hidden'
    },
    containerCenter: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: (Dimensions.get('screen').width <= 500) ? 30 : 300,
        marginTop: 45,
        marginBottom: Dimensions.get('screen').height / 3
    },
    button: {
        backgroundColor: '#0070FF',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 15,
        alignItems: 'center',
        marginTop: 20
    },
    input: {
        backgroundColor: '#f5f5f5',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55,
        fontFamily: 'DM-Regular',
        color: '#000',
        fontSize: 16
    },
    inputDate: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 55
    },
    header: {
        backgroundColor: '#0070FF',
        paddingHorizontal: 30,
        paddingBottom: 10,
        paddingTop: 10 + Constants.statusBarHeight,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    backButton: {
        backgroundColor: '#2484FF',
        width: 'auto',
        height: 50,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15
    },
    buttonLink: {
        backgroundColor: '#fff',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        paddingVertical: 13,
        width: (Dimensions.get('screen').width / 2) - 30,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    buttonMore: {
        backgroundColor: '#0070FF',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 13,
        borderRadius: 18,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        minWidth: 100
    },
    listUser: {
        backgroundColor: '#f5f5f5',
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderColor: '#ededed',
        borderTopWidth: 1,
    },
    containerIcon: {
        backgroundColor: '#fff',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        width: 55,
        height: 55,
        alignItems: 'center',
        justifyContent: 'center'
    },
    positionCricle: {
        position: 'absolute',
        bottom: - Dimensions.get('screen').width / 2,
        right: - Dimensions.get('screen').width / 2,
        zIndex: -1,
        overflow: 'hidden'
    },
    circle: {
        backgroundColor: '#f8d54c',
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').width,
        borderRadius: Dimensions.get('screen').width,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    containerTitle: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: 15,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    responsiveCallButton: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '47%',
        marginTop: 0
    },
    responsiveCall: {
        width: (Dimensions.get('screen').width <= 500) ? Dimensions.get('screen').width - 40 : '30%'
    },
});